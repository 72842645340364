import { defineComponent } from "vue";
import ProgressSpinner from "primevue/progressspinner";
import { useGlobalStore } from "@/stores/global-store.ts";
export default defineComponent({
  name: "AppLoading",
  components: {
    ProgressSpinner: ProgressSpinner
  },
  setup: function setup() {
    var globalStore = useGlobalStore();
    return {
      globalStore: globalStore
    };
  }
});