import { defineStore } from "pinia";
import { ref } from "vue";

interface RequestStore {
  goBackSecurity: boolean;
  dialogVisible: boolean;
}

export const useRequestStore = defineStore("request-store", {
  state: (): RequestStore => {
    return {
      goBackSecurity: true,
      dialogVisible: false,
    };
  },
  actions: {
    setGobackSecurityTrue() {
      this.goBackSecurity = true;
    },
    setGobackSecurityFalse() {
      this.goBackSecurity = false;
    },
    setDialogVisibleTrue() {
      this.dialogVisible = true;
    },
    setDialogVisibleFalse() {
      this.dialogVisible = false;
    },
  },
});
